import InlineFigure from 'components/InlineFigure'
import Note from 'components/Note'
import Sticker from 'components/Sticker'
import Tree from 'components/Tree'

// Recursively build a tree abstraction from a root element
const root = document.querySelector('.tree #root')
if (root) {
  const tree = new Tree(root, {
    onclick: (e, branch) => {
      if (!branch.element.id) return
      const hash = branch.isOpen
        ? '#' + branch.element.id
        : branch.parent
          ? '#' + branch.parent.element.id
          : ''
      window.history.replaceState(null, null, document.location.pathname + hash)
    }
  })

  tree.close()

  // Open a branch matching the URL hash
  const target = window.location.hash && tree.find(branch =>
    branch.element.id === window.location.hash.substr(1)
  )
  if (target) target.open()
}

// Init all found stickers
const stickers = document.querySelectorAll('.sticker')
for (const sticker of stickers) Sticker(sticker)

// Init all found article notes
const notes = document.querySelectorAll('article .note, article h4 .author, .inline-figure .note')
for (const note of notes) Note(note)

// Init all found inline figures
const figures = document.querySelectorAll('span.inline-figure')
for (const figure of figures) InlineFigure(figure, { single: document.body.dataset.template === 'home' })
