export default (element, { single = false } = {}) => {
  const image = element.querySelector('img')

  image.addEventListener('click', e => {
    if (single) { // Ensure all other inline figures are closed
      const openSiblings = document.querySelectorAll('.' + element.getAttribute('class') + '.is-open')
      for (const openSibling of openSiblings) close(openSibling)
    }

    // Open or close the inline figure
    toggle(element)
  })
}

function toggle (element) {
  if (element.classList.contains('is-open')) close(element)
  else open(element)
}

function open (element) {
  element.classList.add('is-open')
  swapSrc(element.querySelector('img'))
}

function close (element) {
  element.classList.remove('is-open')
  swapSrc(element.querySelector('img'))
}

function swapSrc (image) {
  const swap = image.src
  image.src = image.dataset.src
  image.dataset.src = swap
}
