// Return the heading level or 7
const getLevel = el => +(el.tagName.match(/H(\d)/) || [])[1] || 7

export default class Branch {
  constructor (el, {
    parent = null,
    onclick = function () {}
  } = {}) {
    // Link DOM and Tree abstractions
    this.element = el
    this.parent = parent

    // Compute basic properties
    this.index = [...el.parentElement.children].indexOf(el)
    this.level = getLevel(el)

    // Recursively build all branches from this instance
    this.branches = []
    for (let index = this.index + 1; index < el.parentElement.children.length; index++) {
      const candidate = el.parentElement.children[index]
      const level = getLevel(candidate)

      if (level <= this.level) break

      const isNextLevel = (level === this.level + 1)
      const isDirectDescendant = (level > this.level && index === this.index + 1)

      if (isNextLevel || isDirectDescendant) {
        this.branches.push(new Branch(candidate, { parent: this, onclick }))
      }
    }

    // Bind DOM element
    this.element.classList.add('branch')
    this.element.classList.toggle('has-sup', !!this.element.querySelector('sup'))
    this.element.addEventListener('click', e => {
      this.toggle()
      onclick(e, this)
    })
  }

  get isRoot () {
    return !this.parent
  }

  get siblings () {
    return this.parent
      ? this.parent.branches.filter(branch => branch.index !== this.index)
      : []
  }

  get nextSibling () {
    return this.parent
      ? this.parent.branches.find(branch => branch.index > this.index)
      : null
  }

  get hasBranches () {
    return this.branches.length > 0
  }

  update () {
    // Add various helper classes, used to display various informations based on
    // branch states
    this.element.classList.toggle('is-open', this.hasBranches && this.isOpen)
    this.element.classList.toggle('has-children', this.hasBranches)
    this.element.classList.toggle('has-next-sibling', !!this.nextSibling)
    if (this.nextSibling) this.nextSibling.element.classList.toggle('is-next-after-open', this.isOpen)

    // Update element visiblity based on parent open state
    this.element.classList.toggle('is-hidden', this.parent && !this.parent.isOpen)

    // Recursively update all descendants
    this.branches.forEach(branch => branch.update())
  }

  toggle (options) {
    if (this.isOpen) this.close(options)
    else this.open(options)
  }

  open ({ update = true } = {}) {
    this.isOpen = true
    // Always close all siblings when opening a branch (like an input radio)
    this.siblings.forEach(sibling => sibling.close())
    // Ensure parent branch is open
    if (this.parent && !this.parent.isOpen) this.parent.open()
    if (update) this.update()
  }

  close ({ update = true } = {}) {
    this.isOpen = false
    // Allways close all descendants when closing a branch
    this.branches.forEach(branch => branch.close({ update: false }))
    if (update) this.update()
  }

  find (match) {
    if (match(this)) return this
    for (const branch of this.branches) {
      if (match(branch)) return branch
      const result = branch.find(match)
      if (result) return result
    }
  }
}
