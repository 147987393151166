import { clamp } from 'missing-math'

function getPointerPosition (e) {
  const x = e.touches ? e.touches[0].pageX : e.pageX
  const y = e.touches ? e.touches[0].pageY : e.pageY
  return [x, y]
}

class Draggable {
  constructor (element, { padding = 30 } = {}) {
    this.element = element

    this.x = 0
    this.y = 0
    this.padding = padding
    this.ppos = [this.x, this.y]
    this.seed = Math.random()

    const { width, height } = this.element.getBoundingClientRect()
    this.width = width
    this.height = height

    this.hijackLink()

    element.addEventListener('touchstart', this.beginDrag.bind(this))
    element.addEventListener('mousedown', this.beginDrag.bind(this))
    window.addEventListener('mousemove', this.drag.bind(this))
    window.addEventListener('touchmove', this.drag.bind(this))
    window.addEventListener('mouseup', this.dragEnd.bind(this))
    window.addEventListener('mouseleave', this.dragEnd.bind(this))
    window.addEventListener('touchend', this.dragEnd.bind(this))

    window.addEventListener('resize', this.update.bind(this))
  }

  hijackLink () {
    const a = this.element.querySelector('a')
    if (!a || !a.href) return

    a.style.pointerEvents = 'none'
    this.element.addEventListener('click', e => {
      e.stopPropagation()
      e.preventDefault()
      if (e.target.tagName === 'BUTTON') return
      if (this.hasMoved) return
      window.open(a.href, '_blank')
    })
  }

  beginDrag (e) {
    if ([e.target, e.target.parentNode].find(el => el && ['BUTTON', 'A'].includes(el.tagName))) return

    this.isDragging = true
    this.hasMoved = false
    this.element.classList.add('is-dragging')
    this.element.style.zIndex = (this.element.style.zIndex || 0) + 1

    const [x, y] = getPointerPosition(e)
    this.ppos = [x, y]
  }

  drag (e) {
    if (!this.isDragging) return

    const [x, y] = getPointerPosition(e)
    this.x += x - this.ppos[0]
    this.y += y - this.ppos[1]
    this.ppos = [x, y]
    this.hasMoved = true

    this.update()
  }

  dragEnd (e) {
    this.isDragging = false
    this.element.classList.remove('is-dragging')
  }

  update (callback) {
    window.requestAnimationFrame(() => {
      const width = window.innerWidth
      const height = window.innerHeight
      const x = clamp(this.x, this.padding, width - this.width - this.padding)
      const y = clamp(this.y, this.padding, height - this.height - this.padding)
      const alpha = 20 * Math.sin((this.seed * 1000) + (x / width + y / height) * Math.PI / 2)

      this.element.style.transform = `translate(${x}px, ${y}px) rotate(${alpha}deg) `
      if (typeof callback === 'function') callback()
    })
  }
}

export default element => {
  // Bind close events
  const button = element.querySelector('button')
  if (button) {
    // Keep close for the next 24 hours
    const lastClosed = parseInt(window.localStorage.getItem('lastClosed') || 0)
    if (lastClosed + (24 * 60 * 60 * 1000) > Date.now()) {
      element.remove()
      return
    }

    button.addEventListener('click', e => {
      window.localStorage.setItem('lastClosed', Date.now())
      element.remove()
    })
  }

  // Init position and ind drag events
  window.addEventListener('DOMContentLoaded', e => {
    const draggable = new Draggable(element)
    draggable.x = Math.random() * window.innerWidth * 0.75
    draggable.y = window.innerHeight * 0.5 + Math.random() * window.innerHeight * 0.25
    draggable.update(() => {
      void element.offsetWidth
      element.classList.add('is-loaded')
    })
  })
}
